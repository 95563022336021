import { RefObject, useState } from "react";
import styles from "./nav-bar.module.css";

type NavBarProps = {
  homeRef: RefObject<HTMLDivElement>;
  aboutRef: RefObject<HTMLDivElement>;
  notifiedRef: RefObject<HTMLDivElement>;
};

export default function NavBar({
  homeRef,
  aboutRef,
  notifiedRef,
}: NavBarProps) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  const scrollToSection = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      closeMenu(); // Close mobile menu after clicking on a link
    }
  };

  return (
    <section className={`${styles["nav-bar-container"]}`}>
      <link rel="preload" href="/X.svg" as="image" />
      <link rel="preload" href="/burgermenu.svg" as="image" />

      <div
        className={`${styles["nav-bar-direct-container"]} ${
          isMobileMenuOpen ? styles["fixed"] : styles["relative"]
        }`}
      >
        <img
          onClick={closeMenu}
          src="/logoae.png"
          alt="O"
          className="creator"
          style={{ zIndex: 3 }}
        />
        <div className={styles["nav-bar-right"]}>
          <div
            className="font-greycliff pointer"
            onClick={() => scrollToSection(homeRef)}
          >
            Home
          </div>
          <div
            className="font-greycliff pointer"
            onClick={() => scrollToSection(aboutRef)}
          >
            About
          </div>
          <button
            className={`${styles["nav-bar-notified"]} font-greycliff pointer`}
            onClick={() => scrollToSection(notifiedRef)}
          >
            Get notified
          </button>
        </div>
        <div
          className={`${styles["mobile-menu-button"]} ${
            isMobileMenuOpen ? styles["open"] : ""
          }`}
          onClick={toggleMenu}
        >
          <img
            src={isMobileMenuOpen ? "/X.svg" : "/burgermenu.svg"}
            alt="Menu Icon"
            className={styles["mobile-menu-button-icon"]}
          />
        </div>
        {isMobileMenuOpen && (
          <>
            <div className={styles["overlay"]} onClick={closeMenu}></div>
            <div className={styles["mobile-menu"]}>
              <div
                className="font-greycliff font-greycliff-regular"
                onClick={() => scrollToSection(homeRef)}
              >
                Home
              </div>
              <div
                className="font-greycliff font-greycliff-regular"
                onClick={() => scrollToSection(aboutRef)}
              >
                About
              </div>
              <div
                className="gradient-text font-greycliff font-greycliff-regular"
                onClick={() => scrollToSection(notifiedRef)}
              >
                Get notified
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
