import Page from "./page/page";

function App() {
  return (
    <div className="App">
      {" "}
      <Page />
    </div>
  );
}

export default App;
