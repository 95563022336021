import FooterTitles from "./Components/footer-titles/footer-titles";
import FooterSocials from "./Components/footer-socials/footer-socials";
import FooterSignature from "./Components/footer-signature/footer-signature";

import styles from "./footer.module.css";

export default function Footer() {
  return (
    <div className={styles["footer-container"]}>
      <FooterTitles />
      <FooterSocials />
      <FooterSignature />
    </div>
  );
}
