import styles from "./home-title.module.css";

export default function Hometitle() {
  return (
    <section className={styles["home-container"]}>
      <div className={styles["home-titles-container"]}>
        <p
          className={`${styles["home-titles-style"]} main-title font-greycliff-bold`}
        >
          <span className="gradient-text-reversed font-greycliff-bold ">
            From the UAE,
          </span>{" "}
          a Leading Visionary Syndicate
        </p>
        <div className={styles["home-titles-secondary-style-container"]}>
          <div
            className={styles["home-titles-secondary-style-direct-container"]}
          >
            <p
              className={`${styles["home-titles-secondary-style"]} poppins`}
              style={{ fontWeight: 400 }}
            >
              Empowering creators & redefining the future of media. where
              innovations thrive and dreams take flight.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
