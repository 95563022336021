import styles from "./notified.module.css";

import NotifiedTitles from "./Components/notified-titles/notified-titles";
import NotifiedForm from "./Components/notified-form/notified-form";

export default function Notified() {
  return (
    <div className={styles["notified-container"]}>
      <NotifiedTitles />
      <NotifiedForm />
    </div>
  );
}
