import styles from "./footer-socials.module.css";

export default function FooterSocials() {
  return (
    <>
      <h3 className={`${styles["footer-socials-title"]} poppins`}>
        Find us on
      </h3>
      <div className={styles["footer-socials-container"]}>
        <a
          href="https://www.instagram.com/creators.ae"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={37}
            height={37}
            src="/footer-social-icons/instagram.svg"
            alt="Instagram"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.facebook.com/creator.ae"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={37}
            height={37}
            src="/footer-social-icons/facebook.svg"
            alt="Facebook"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.tiktok.com/@creators.ae"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={37}
            height={37}
            src="/footer-social-icons/tiktok.svg"
            alt="Tiktok"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/creators-ae/about/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={37}
            height={37}
            src="/footer-social-icons/linkedin.svg"
            alt="LinkedIn"
            loading="lazy"
          />
        </a>
      </div>
    </>
  );
}
