import NavBar from "./nav-bar";
import styles from "./navigation-bar.module.css";
import { useState, useEffect } from "react";
import { RefObject } from "react";

type NavigationBarProps = {
  homeRef: RefObject<HTMLDivElement>;
  aboutRef: RefObject<HTMLDivElement>;
  notifiedRef: RefObject<HTMLDivElement>;
};

export default function NavigationBar({
  homeRef,
  aboutRef,
  notifiedRef,
}: NavigationBarProps) {
  const [navBar, setNavbar] = useState(false);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY > 0) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    // Add event listener when component mounts
    window.addEventListener("scroll", changeBackground);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div
      className={
        navBar
          ? `${styles["nav-bar-container-main"]} ${styles["active"]}`
          : `${styles["nav-bar-container-main"]}`
      }
    >
      <div className={styles["nav-bar-direct-container-main"]}>
        <NavBar
          homeRef={homeRef}
          aboutRef={aboutRef}
          notifiedRef={notifiedRef}
        />
      </div>
    </div>
  );
}
