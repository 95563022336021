import styles from "./page.module.css";
import { useRef, useState, useEffect } from "react";

import NavigationBar from "../ui/home/Components/navbar/navigation-bar";
import Home from "../ui/home/home";
import About from "../ui/about/about";
import Notified from "../ui/notified/notified";
import Footer from "../ui/footer/footer";

export default function Page() {
  const [loading, setLoading] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const homeRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const notifiedRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1500) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Simulate loading time with a timeout
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5500); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div
        className={`${styles.loadingScreen} ${
          loading ? styles.visible : styles.hidden
        }`}
      >
        <img
          src="/creators_loading.gif" // Replace with the path to your loading GIF
          alt="Loading"
          width={200}
          height={200}
        />
      </div>
      <header className={styles["home-container"]}>
        <img
          className={`${styles["home-background-image"]} ${
            scrolling ? styles["home-background-image-scrolled"] : ""
          }`}
          width={1122}
          height={1122}
          src="/powder.jpg"
          alt=""
        />
        <NavigationBar
          homeRef={homeRef}
          aboutRef={aboutRef}
          notifiedRef={notifiedRef}
        />
        <div className={styles.container}>
          <div ref={homeRef} className="content">
            <Home />
          </div>
        </div>
        <img
          className={styles["home-background-city"]}
          width={2000}
          height={786}
          src="/citysvg.svg"
          alt=""
        />
      </header>
      <main className={styles["skyline-background"]}>
        <div ref={aboutRef} className={styles.container}>
          <About />
        </div>
        <div ref={notifiedRef} className={styles.container}>
          <Notified />
        </div>
        <div className={styles["image-container"]}>
          <img
            className={styles["main-background-image"]}
            width={1203}
            height={1203}
            src="/powder2.jpg"
            alt=""
            loading="lazy"
          />
        </div>
        <footer className={`${styles.container} ${styles.footer}`}>
          <Footer />
        </footer>
      </main>
    </>
  );
}
