import Hometitle from "./Components/home-titles/home-title";

export default function Home() {
  return (
    <>
      {/*<NavBar />*/}
      <Hometitle />
    </>
  );
}
