import AboutTitles from "./Components/about-titles/about-titles";
import AboutCards from "./Components/about-cards/about-cards";
export default function About() {
  return (
    <section>
      <AboutTitles />
      <AboutCards />
    </section>
  );
}
