import styles from "./footer-titles.module.css";

export default function FooterTitles() {
  return (
    <section className={`content ${styles["footer-container"]}`}>
      <div className={styles["footer-creator-container"]}>
        <img
          width={157.9}
          height={29.26}
          src="/logoae.png"
          alt="O"
          className="creator"
        />
      </div>
      <p
        className={`${styles["footer-paragraph"]} poppins`}
        style={{ fontWeight: 300 }}
      >
        Stay tuned to witness the launch of the ultimate creators ecosystem.
      </p>
    </section>
  );
}
