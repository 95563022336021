import { useState, useRef } from "react";
import styles from "./notified-form.module.css";

const NotifiedForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track submission
  const subscribedRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
  };

  const validateEmail = (email: string) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const apiKey: string = process.env.REACT_APP_API_KEY as string;
    const boardId: string = process.env.REACT_APP_BOARD_ID as string;

    const query = `mutation {
      create_item (board_id: ${boardId}, item_name: "${email}") {
        id
      }
    }`;

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch("https://api.monday.com/v2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiKey,
        },
        body: JSON.stringify({ query }),
      });

      if (response.ok) {
        setIsSubmitted(true);
        if (subscribedRef.current) {
          subscribedRef.current.textContent = "Thank you for subscribing!";
        }
        setEmail("");
      } else {
        setError("Failed to subscribe. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to subscribe. Please try again later.");
    }
  };

  return (
    <div
      className={`${styles["notified-form"]} poppins`}
      style={{ fontWeight: 400 }}
    >
      <form onSubmit={handleSubmit}>
        <input
          className={styles["notified-input"]}
          type="text"
          placeholder="Your Email"
          value={email}
          onChange={handleInputChange}
          disabled={isSubmitted}
        />
        <button
          type="submit"
          className={styles["notified-button"]}
          onClick={handleSubmit}
          disabled={isSubmitted}
        >
          Subscribe
        </button>
      </form>

      <div className={styles["error-message"]}>{error}</div>

      <div
        id="subscribed"
        ref={subscribedRef}
        className={styles["subscribed-message"]}
      ></div>
    </div>
  );
};

export default NotifiedForm;
