//import { poppins } from "../../../fonts/fonts";
import styles from "./about-titles.module.css";
export default function AboutTitles() {
  return (
    <section className="content">
      <div className={styles["about-title-container"]}>
        <p className="main-secondary-title font-greycliff font-greycliff-demibold">
          <span className="gradient-text font-greycliff font-greycliff-demibold">
            {" "}
            Vision
          </span>
        </p>
        <p className={`second-title poppins`} style={{ fontWeight: 400 }}>
          Envisioning a world where creators have the tools, resources, and
          opportunities they need to thrive independently in the digital age. We
          aim to make the UAE the global leader in fostering creativity within
          the creators community.
        </p>
      </div>
      <div className={styles["about-title-container"]}>
        <p className="main-secondary-title">
          <span className="gradient-text font-greycliff font-greycliff-demibold">
            Mission
          </span>
        </p>
        <p className={`second-title poppins`} style={{ fontWeight: 400 }}>
          Building the most advanced ecosystem for creators, unparalleled in its
          scope. With revolutionary features to redefine collaboration, we will
          bring new meaning to the future of media.
        </p>
      </div>
    </section>
  );
}
