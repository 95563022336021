import styles from "./about-cards.module.css";

export default function AboutCards() {
  return (
    <section className={styles["about-cards-container"]}>
      <div className={styles["about-card-container"]}>
        <img
          className={styles["about-card-icon-collaboration"]}
          width={136.22}
          height={84.13}
          src="/cards-icons/CollaborationHub.svg"
          alt="Collaboration hub"
          loading="lazy"
        />
        <div
          className={`${styles["about-card-title"]} font-greycliff font-greycliff-demibold`}
        >
          Collaboration Hub
        </div>
        <p
          className={`${styles["about-card-paragraph"]} poppins`}
          style={{ fontWeight: 300 }}
        >
          Seamlessly connect with top creators and brands.
        </p>
      </div>
      <div className={styles["about-card-container"]}>
        <img
          className={styles["about-card-icon-skill"]}
          width={102.71}
          height={97.38}
          src="/cards-icons/Skilldeveloppment.svg"
          alt="Skill developpment"
          loading="lazy"
        />
        <div
          className={`${styles["about-card-title"]} font-greycliff font-greycliff-demibold`}
        >
          Skill Development
        </div>
        <p
          className={`${styles["about-card-paragraph"]} poppins`}
          style={{ fontWeight: 300 }}
        >
          Access resources and training to enhance your skills.
        </p>
      </div>
      <div className={styles["about-card-container"]}>
        <img
          className={styles["about-card-icon-innovative"]}
          width={93.47}
          height={93.47}
          src="/cards-icons/InnovativeSolutions.svg"
          alt="Collaboration hub"
          loading="lazy"
        />
        <div
          className={`${styles["about-card-title"]} font-greycliff font-greycliff-demibold`}
        >
          Innovative Solutions
        </div>
        <p
          className={`${styles["about-card-paragraph"]} poppins`}
          style={{ fontWeight: 300 }}
        >
          Utilize state-of-the-art facilities for content creation.
        </p>
      </div>
    </section>
  );
}
