import styles from "./notified-titles.module.css";

export default function NotifiedTitles() {
  return (
    <section>
      <div className={styles["notified-container"]}>
        <div className={styles["notified-direct-container"]}>
          <p className="main-title" style={{ paddingBottom: "3.5vh" }}>
            <span className="gradient-text font-greycliff font-greycliff-demibold">
              Stay Updated
            </span>
          </p>
          <p className={`second-title poppins`} style={{ fontWeight: 400 }}>
            Enter your email to be the first to know when we launch.
          </p>
        </div>
      </div>
    </section>
  );
}
